import Work1 from "../../assets/work1.png";
import Work2 from "../../assets/work2.png";
import Work3 from "../../assets/work3.png";
import Work4 from "../../assets/work4.png";
import Work5 from "../../assets/work5.png";
import Work6 from "../../assets/work6.png";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    company: "nudgem",
    title: "CTO Co-Founder",
    category: "eCommerce Personalization",
    link: "https://www.nudgem.com/",
    description: [
      "I am CTO and Co-Founder of Nudgem, a no-code, AI-powered, experience builder that brands use to create segmented experiences and storefronts to increase the lifetime value and conversion rate of their most valuable customers.",
      "In the past decade, customer acquisition costs have increased by 222%, further worsened by privacy tracking updates from Apple and Google in the past year. Given that 80% of a retailer's revenue is generated by the top 20% of its customers, the focus is shifting towards increasing the LTV of existing customers, particularly the most loyal ones. From lots of conversations with retailers, we know they have started looking into building custom experiences in-house, but lack the resources and data to do so quickly and effectively.",
      "With nudgem, marketers create beautiful, custom, and gated experiences that integrate into their store websites by choosing from a variety of widgets that best fit their theme, and we will take care of the rest.",
      "We segment shoppers based on onsite behavior and historical order data, and dynamically show personalized experiences and offers that incentivize more purchases, improve product discoverability, increase retention, and create more loyal customers.",
      "We have launched with two mid-market brands that do 8 figures in sales. On average, our customers see a 34% increase in AOV and 2X conversion rates compared to their current store website."
    ]
  },
  {
    id: 1,
    image: Work2,
    company: "Handwave.AI",
    title: "Venture Partner",
    category: "Fundraising Platform",
    link: "https://handwave.ai/",
    description: [
      "I was a venture partner at Handwave.AI, where I lead investments and deal flow.",
      "As a 2x founder, I know just how hard it can be to get in front of top investors without warm intros. I also know how time consuming and arduous the fundraising process can take.",
      "With Handwave, AI startup founders are no longer burdened by these traditional fundraising challenges. It takes only 60 seconds for them to pitch their ideas to the world's leading AI investors. This platform enhances collaboration between founders and investors, allowing for more efficient communication and decision-making, and more deals to be made.",
      "We empower founders to focus on innovation and growth, and spend less time worrying about how to get in front of the right people. We also provide investors with broader access to the latest AI startups looking for funding, enabling more productive interactions and better-informed investment decisions.",
      "Handwave has garnered support from prominent investors, including NVIDIA (nVentures), Bain Capital Ventures, Battery Ventures, MongoDB Ventures, General Catalyst, F Prime Capital, First Round Capital, Greycroft, SignalFire, Alpha Square Group, Samsung, Salesforce Ventures, Prologis Ventures, Intel Capital, IVP, Radical Ventures, PowerPlay Capital, and others."
    ]
  },
  {
    id: 2,
    image: Work3,
    company: "DevWorld.AI",
    title: "Founder",
    category: "AI Community",
    link: "https://www.devworld.ai/",
    description: [
      "As a builder and developer, I struggled to find easy to follow and update to date resources to help me learn, develop, and apply AI in my everyday life. I also had a hard time finding a community of like-minded people to learn and grow with. So I decided to build one myself.",
      "DevWorld.AI is a free community for builders, developers, and AI enthusiasts. There are hands-on tutorials for beginners to learn how to use tools like OpenAI, Langchain, HuggingFace, Pinecone, Python, Node.js, and many others to build useful applications quickly.",
      "There are also resources on remote and in-person events happening in major cities like New York and San Francisco, quality AI blogs to follow, and podcasts to listen to.",
      "Soon, there will also be analysis on the latest research papers and trends in the industry, such as the OCR-free visual document understanding transformer paper proposed by the Clova AI team, which is supposed to improve data extraction accuracy and make it easier to generate synthetic training data.",
      "There will also be podcasts with experts in the industry. This is an exciting time we live in, and I want to make sure everyone has access to the resources they need to learn and grow."
    ]
  },
  {
    id: 4,
    image: Work5,
    company: "Bluecore",
    title: "Engineering Manager",
    category: "eCommerce Personalization",
    link: "https://www.bluecore.com/",
    description: [
      "As a member of Bluecore's engineering leadership team, I led a team of talented frontend, backend, and fullstack developers to build a marketing campaign orchestration dashboards used by 800+ of the largest retailers in the world. Our customers include Nike, Sephora, CVS, Express, Gap, Lenovo, and others.", 
      "I fostered a high performing environment and received a 93% approval rating from my direct reports on HR's NPS survey. This was the highest rating in the engineering org. To build team culture in a remote environment, I also introduced weekly team building events. Eventually, other managers adopted this practice as well. I am also proud of launching and directing an org-wide engineering mentorship program, achieving a 70% voluntary participation rate and a 75% positive rating.",
      "I am especially proud of working with my team to reduce our team's bug backlog from 56 to 19 in just two quarters. It had been stuck at 50+ for a year before that. Afterwards, we were able to further reduce it and consistently maintain the count at single digits.", 
      "When I first got to Bluecore, my team was responsible for only the email channel. A few months later, our responsibility expanded to both email and SMS channels as Bluecore's business grew.",
      "I also had the pleasure of building my team from scratch. Within three months of my arrival, the team's headcount decreased to only two engineers. I conducted 60+ hiring manager interviews and added 5 new engineers."
    ]
  },
  {
    id: 5,
    image: Work6,
    company: "IBM Watson",
    title: "Tech Lead and Architect",
    category: "Healthcare Blockchain SAAS",
    link: "https://www.ibm.com/watson",
    description: [
      "I was fortunate to join the Watson Health blockchain team as the second engineering hire, and stayed on the same team for the entire 5 years. We built IBM's first ever healthcare blockchain product.",
      "From there, we joined forces with the FDA and CDC to create products that facilitated the exchange of protected health data in a HIPAA compliant way.",
      "I was promoted twice during my tenure at IBM Watson. Eventually, I took on the role of technical owner of the Consent Manager product, which sped up the exchange of health data between electronic health record (EHR) systems.",
      "I also took on the role of technical owner and lead of IBM's Health Utility Network (HUN)'s blockchain SDK. The HUN network comprised of the largest healthcare payers, providers, and insurance companies in the US, including Anthem, Aetna, Cigna, Pfizer and HCSC. They used the blockchain SDK to build custom products that served millions of patients.",
      "One of the most difficult problems we solved was the optimization of transaction latency. Ultimately, we designed and implemented a way to decrease latency by an average of 85% through a combination of caching, code optimization, and vertical and horizontal scaling."
    ]
  }
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
  {
    name: "design",
  },
];
