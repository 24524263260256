import React, { useState } from "react";
import "./qualification.css";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="qualification section">
      <h2 className="section__title">My Journey So Far</h2>
      <span className="section__subtitle"></span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Education
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Experience
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">CTO Co-Founder</h3>
                <span className="qualification__subtitle">
                  Nudgem
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> October 2022 - Present
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Host</h3>
                <span className="qualification__subtitle">
                  Driving Forces of Tomorrow Podcast (Spotify)
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> August 2022 - Present
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Venture Partner</h3>
                <span className="qualification__subtitle">
                  Handwave.AI 
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> May 2023 - November 2023
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Engineering Manager</h3>
                <span className="qualification__subtitle">
                  Bluecore
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> May 2021 - Oct 2022
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Tech Lead, Advisory Software Engineer</h3>
                <span className="qualification__subtitle">
                  IBM Watson
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> June 2016 - May 2021
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">B.S. Computer Science</h3>
                <span className="qualification__subtitle">
                  Boston University
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> Sep 2012 - May 2016
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
              </div>

              <div>
                <h3 className="qualification__title">Cyber Defenders Program</h3>
                <span className="qualification__subtitle">
                  Lawrence Livermore National Laboratory
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> Summers 2015 and 2014
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
