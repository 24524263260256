import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";
import Image4 from "../../assets/testimonial4.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    name: "Rodrigo Cespedes Daza",
    title: "Tech lead & senior software engineer",
    relationship: "Reported to me at Bluecore",
    description: [
      "I worked with Alex at Bluecore for over 2 years. Alex achieved a great deal. At Bluecore, he came into a team that was supposed to have a minimum of 5 developers but only had 2; we weren't even staying afloat, we were drowning. When he left, the team was successfully completing projects on time and was tackling several more.",
      "Alex was instrumental in turning our team around. He interviewed each and every individual that the team currently has (me being the only member that was not hired by him). Alex did in the order of several dozen interviews in order to pick the right candidates, his effort showed as we became a tightly knit and high achieving team.",
      "We also worked together at OnRamp Labs to bring the product from zero to one. He handled all layers of responsibility, from product execution, to management, to hands on coding; he interacted with all the verticals, guaranteeing delivery of the project. He proactively looked out for ways to optimize the team's workflows, he'd re-evaluate and adjust our priorities at the right time, always keeping effective balance between order and agility.",
      "Having worked with Alex across two companies, we enjoyed a sense of comradery that is rarely achieved in most teams. The level of trust within our teams was high, we were able to work fast, pivot quickly and prioritize leaning on each other for good results.",
      "The passion and commitment that Alex showed in getting our team to a successful state was great, he's an asset to any engineering team."
    ]  
  },
  {
    id: 2,
    image: Image2,
    name: "Woong Ah Yoon",
    title: "CTO at GGQ",
    title2: "Former Head of Samsung Pay, Blockchain, Private Share, and Digital Key at Samsung",
    relationship: "Directly managed me at IBM Watson",
    description: [
      "I worked with Alex for four years at IBM. I was the first technical hire on the Watson Health blockchain team, and Alex was the second. Together, we built IBM’s first healthcare blockchain product which served large payers, providers, insurance companies, and government agencies. We developed several products from scratch including the IBM Consent Manager and the Health Utility Network SDK, for which Alex was the technical owner. As Alex’s direct manager, I witnessed his growth into one of the best engineers and technical leaders on the team. In addition to his technical abilities, Alex has strong business acumen, and often gave thoughtful feedback to the product team. He is an asset for any organization.",
    ]
  },
  {
    id: 3,
    image: Image3,
    name: "Akkhilaysh Shetty",
    title: "Software engineer",
    relationship: "Reported to me at Bluecore",
    description: [
      "I had the pleasure of working with Alex during my time at Bluecore, and I can confidently say that he is a top-notch manager and leader. Alex's extensive experience as a software engineer made him a valuable technical resource as well. He consistently demonstrated his genuine interest in the underlying technology and was always eager to share his knowledge and expertise with others.", 
      "One of Alex's most notable qualities is his exceptional communication skills. He has a high EQ and is able to connect with team members at all levels, making him an approachable and effective leader. He was always available to answer questions and provide guidance, and his supportive and inclusive leadership style made him a great mentor and friend to many on the team.", 
      "In addition to his technical and communication abilities, Alex is an adept problem solver. He has a unique ability to take complex issues, ask questions to break them down into manageable components, and strives to find the best solution for the team. His leadership and problem-solving skills were a huge asset to the org and any team would be lucky to have him on their side.",
    ]
  },
  {
    id: 4,
    image: Image4,
    name: "Tony Chen",
    title: "Senior software engineer",
    relationship: "Reported to me at Bluecore",
    description: [
      "I had the privilege of working with Alex and was consistently impressed by his technical expertise, leadership skills, and dedication to his team. Alex has a deep understanding of software development and a keen eye for detail, which made him a valuable contributor to the technical decision-making processes of our team. He was always available to offer guidance and support, and he effectively motivated and inspired his team to achieve successful project delivery. In addition to his technical abilities, Alex is an excellent leader who fostered a positive and inclusive work environment. He took the time to get to know each team member and their strengths, and he consistently demonstrated his commitment to their success.",
    ]
  },
];
