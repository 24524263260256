import React from "react";

const WorkItems = ({ item }) => {
  return (
    <div className="work__card" key={item.id}>
      <img src={item.image} alt="" className="work__img" />
      <h3 className="work__company">{item.company}</h3>
      <h4 className="work__title">{item.title}</h4>
      <h4 className="work__category">{item.category}</h4>
      <p>
        {item.description.map((line) => {
          return <>{line}<br></br><br></br></>;
        })}
      </p>
      <a href={item.link} className="work__button">
        See more
        <i className="bx bx-right-arrow-alt work__button-icon"></i>
      </a>
    </div>
  );
};

export default WorkItems;
