import React from "react";
import { useRef } from 'react';
import "./testimonial.css";
import { Data } from "./Data";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

const Testimonials = () => {
  const swiperRef = useRef();

  return (
    <section className="testimonial container section" id="recommendations">
      <h2 className="section__title">What My Team Says About Me</h2>
      {/* <span className="section__subtitle">Testimonial</span> */}

      <div className="navigation__buttons">
        <button className="leftBtn" onClick={() => swiperRef.current?.slidePrev()}><i className="uil uil-angle-left-b"></i></button>
        <button className="rightBtn" onClick={() => swiperRef.current?.slideNext()}><i className="uil uil-angle-right-b"></i></button>
      </div>

      <Swiper
        className="testimonial__container"
        loop={false}
        grabCursor={true}
        spaceBetween={24}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          576: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 48,
          },
        }}
        modules={[Pagination]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {Data.map(({ id, name, relationship, image, title, title2, description }) => {
          return (
            <SwiperSlide className="testimonial__card" key={id}>
              <img src={image} alt="" className="testimonial__img" />

              <h3 className="testimonial__name">{name}</h3>
              <h4 className="testimonial__title">{title}</h4>
              <h4 className="testimonial__title2">{title2}</h4>
              <h5 className="testimonial__relationship">{relationship}</h5>
              <p className="testimonial__description">
                {description.map((line) => {
                  return <>{line}<br></br><br></br></>;
                })}
              </p>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
