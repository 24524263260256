import React from "react";
import "./patents.css";


const Patents = () => {
  return (
    <section className="patents section" id="publications">
      <h2 className="section__title">Publications and Patents</h2>
      <span className="section__subtitle"></span>

      <div className="patents__container container grid">
        <div className="patents__content">
          <div>
            <h3 className="patents__title">Publication on IEEE</h3>
            <h3 className="patents__number">12 Citations</h3>
            <h3 className="patents__country">DOI: 10.1147</h3>
            <h3 className="patents__title">
              Blockchain: An enabler for healthcare and life sciences transformation
            </h3>
          </div>

          <a href="https://ieeexplore.ieee.org/document/8700241" className="seemore__button">
            See more
            <i className="bx bx-right-arrow-alt work__button-icon"></i>
          </a>
        </div>

        <div className="patents__content">
          <div>
            <h3 className="patents__title">Patent</h3>
            <h3 className="patents__number">US20190354693A1</h3>
            <h3 className="patents__country">United States</h3>
            <h3 className="patents__title">
              Blockchain for managing access to medical data
            </h3>
          </div>

          <a href="https://patents.google.com/patent/US20190354693A1/en" className="seemore__button">
            See more
            <i className="bx bx-right-arrow-alt work__button-icon"></i>
          </a>
        </div>

        <div className="patents__content">
          <div>
            <h3 className="patents__title">Patent</h3>
            <h3 className="patents__number">US20200162238A1</h3>
            <h3 className="patents__country">United States</h3>
            <h3 className="patents__title">
            User access control in blockchain
            </h3>
          </div>

          <a href="https://patents.google.com/patent/US20200162238A1/en" className="seemore__button">
            See more
            <i className="bx bx-right-arrow-alt work__button-icon"></i>
          </a>
        </div>

        <div className="patents__content">
          <div>
            <h3 className="patents__title">Patent</h3>
            <h3 className="patents__number">US20220004647A1</h3>
            <h3 className="patents__country">United States</h3>
            <h3 className="patents__title">
              Blockchain implementation to securely store information off-chain
            </h3>
          </div>

          <a href="https://patents.google.com/patent/US20220004647A1/en" className="seemore__button">
            See more
            <i className="bx bx-right-arrow-alt work__button-icon"></i>
          </a>
        </div>

        <div className="patents__content">
          <div>
            <h3 className="patents__title">Patent</h3>
            <h3 className="patents__number">GB2607780A</h3>
            <h3 className="patents__country">United Kingdom</h3>
            <h3 className="patents__title">
              Index structure for blockchain ledger
            </h3>
          </div>

          <a href="https://patents.google.com/patent/GB2607780A/en" className="seemore__button">
            See more
            <i className="bx bx-right-arrow-alt work__button-icon"></i>
          </a>
        </div>
      </div>
     </section> 
  );
};

export default Patents;
